import React, { useEffect, useState } from "react";
import ReviewerContext from "./ReviewerContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoaderSvg } from "../assets/svg/LoaderSvg";
import styled from "styled-components";
import { Colors } from "../styles/global";
import { boolean } from "yup";
import { configValues } from "../config-values";
import { useProfile } from "../state/profile";

interface IProps {
  children: React.ReactNode;
}

const Loader = styled.div`
  width: 100%;
  border: none;
  text-align: center;
  background-color: #ffffff;
`;
const ReviewerProvider = ({ children }: IProps) => {
  const { login, profile } = useProfile();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [params] = useSearchParams();
  const reviewer = params.get("reviewer");
  const email = params.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    if (!reviewer || !email || reviewer !== "true" || !!profile) {
      if (profile) {
        setIsLoading(false);
      }
      return;
    }

    localStorage.setItem("reviewer-email-stored", email);
    setIsLoading(true);

    const data = {
      email: configValues.project.appTesterEmail,
      password: configValues.project.appTesterPassword,
    };

    login(data).then((p) => {
      if (p.email && p._id) {
        navigate("/explore");
      }
    });
  }, [reviewer, email, login, navigate, profile]);

  return (
    <ReviewerContext.Provider value={null}>
      {isLoading && (
        <Loader>
          <LoaderSvg
            c={Colors.primary}
            width="300px"
            height="300px"
            strokeWidth="1"
          />
        </Loader>
      )}
      {!isLoading && <>{children}</>}
    </ReviewerContext.Provider>
  );
};

export { ReviewerProvider };
