import styled from "styled-components";
import { BottomContainer } from "../../components/nav/BottomContainer";
import { CreateListHeader } from "../../components/nav/CreateListHeader";
import { CheckboxItem } from "../../components/items/CheckboxItem";
import { days } from "../../utils/days";
import { capitalize } from "lodash";
import { useMealPlan } from "../../state/mealPlan";
import { IMealPlanDay } from "../../types/mealplan";
import { useShoppingList } from "../../state/shoppingList";
import { Colors } from "../../styles/global";
import { useProfile } from "../../state/profile";
import { useNavigate } from "react-router-dom";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { useState } from "react";

const Content = styled.div`
  flex: 1;
  display: flex;
  padding-top: 90px;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 1);
`;

const Title = styled.h2`
  margin: 0;
  padding: 0 1rem;
  font-size: 20px;
  line-height: 2rem;
  font-weight: 700;
`;

const ItemList = styled.div`
  display: flex;
  margin-top: 3rem;
  padding-left: 1.5rem;
  flex-direction: column;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Button = styled.button<{ disabled?: boolean }>`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
  background-color: ${Colors.primary};
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
`;

export const CreateShoppingList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { profile } = useProfile();
  const { mealPlan, meals } = useMealPlan();
  const { selectedDays, setDay, generate } = useShoppingList();

  const handleSubmit = async () => {
    if (mealPlan) {
      setLoading(true);
      const selectedIngredients: string[] = [];
      Object.entries(selectedDays).forEach(([day, selected]) => {
        if (selected) {
          const dayMealIds = mealPlan[day] as IMealPlanDay;
          const brekfastMealId = dayMealIds.breakfast[0];
          const lunchMealId = dayMealIds.lunch[0];
          const dinnerMealId = dayMealIds.dinner[0];
          selectedIngredients.push(
            ...meals[brekfastMealId].ingredients,
            ...meals[lunchMealId].ingredients,
            ...meals[dinnerMealId].ingredients
          );
          /* TODO: filter only selected meals (current active meal) */
          /* const mealId = dayMealIds[0]; */
        }
      });
      await generate(profile._id, selectedIngredients);
      setLoading(false);
      navigate("/shopping/initial");
    }
  };

  return (
    <>
      <CreateListHeader />
      <Content>
        <Title>
          Select which days you would like to include into your shopping list?
        </Title>
        <ItemList>
          {days.map((day) => (
            <CheckboxItem
              key={day}
              title={capitalize(day)}
              active={!!selectedDays[day]}
              onClick={() => setDay(day)}
            />
          ))}
        </ItemList>
      </Content>
      <BottomContainer>
        <Button onClick={handleSubmit}>
          {loading ? <LoaderSvg /> : "Create shopping list"}
        </Button>
      </BottomContainer>
    </>
  );
};
