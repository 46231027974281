import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { IShoppingList } from "../types/shoppingList";
import { Api } from "../apis/api";
import { shouldFetchList } from "../utils/shoppingList";

const STORAGE_KEY = "shopping-list";

export interface IShoppingListState {
  shoppingList: IShoppingList | null;
  selectedDays: Record<string, boolean>;
  lastFetched: string | null;
  setDay: (day: string) => void;
  setPurchased: (index: number) => void;
  clearList: () => void;
  generate: (uid: string, ingredients: string[]) => Promise<void>;
  fetchShoppingList: (shoppingListId?: string) => Promise<void>;
  forceFetchShoppingList: (shoppingListId?: string) => Promise<void>;
  resetShoppingList: () => void;
}

export const useShoppingList = create<IShoppingListState>()(
  immer(
    persist(
      (set, get) => ({
        selectedDays: {},
        shoppingList: null,
        lastFetched: null,
        setDay(day) {
          set((s) => {
            s.selectedDays[day] = !s.selectedDays[day];
          });
        },
        setPurchased(index) {
          set((s) => {
            s.shoppingList.ingredients[index].purchased =
              !s.shoppingList.ingredients[index].purchased;
          });
        },
        clearList() {
          set((s) => {
            s.shoppingList.ingredients?.forEach((item) => {
              item.purchased = false;
            });
          });
        },
        async generate(uid, ingredients) {
          try {
            const shoppingList = await Api.generateShoppingList(
              uid,
              ingredients
            );
            set((s) => {
              s.shoppingList = shoppingList;
              s.lastFetched = new Date().toISOString();
            });
          } catch (e) {
            console.log(e);
          }
        },
        resetShoppingList() {
          localStorage.removeItem(STORAGE_KEY);
          set((s) => {
            s.shoppingList = null;
            s.selectedDays = {};
            s.lastFetched = null;
          });
        },
        async fetchShoppingList(shoppingListId) {
          const state = get();
          if (shouldFetchList(state, shoppingListId)) {
            const list = await Api.getShoppingList(shoppingListId);
            set((s) => {
              s.shoppingList = list;
              s.lastFetched = new Date().toISOString();
            });
          }
        },
        async forceFetchShoppingList(shoppingListId) {
          const list = await Api.getShoppingList(shoppingListId);
          set((s) => {
            s.shoppingList = list;
            s.lastFetched = new Date().toISOString();
          });
        },
      }),
      {
        name: STORAGE_KEY,
      }
    )
  )
);
