import { FC } from "react";

interface Props {
  c?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
}

export const LoaderSvg: FC<Props> = ({
  c = "#ffffff",
  width = "30px",
  height = "30px",
  strokeWidth = "15",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={c}
      strokeWidth={strokeWidth}
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
);
