import { configValues } from "../../../config-values";

export const LogoSvg = () => (
  <svg width={146} height={30} viewBox="0 0 146 30">
    <g id="logo" transform="translate(-235.707 -449.173)">
      <path
        id="Subtraction_4"
        data-name="Subtraction 4"
        d="M262.38,479.173H239.033a3.326,3.326,0,0,1-3.326-3.326V452.5a3.326,3.326,0,0,1,3.326-3.326H262.38a3.326,3.326,0,0,1,3.327,3.326v23.347A3.326,3.326,0,0,1,262.38,479.173Zm-16.751-18.517a1,1,0,0,0-.728.318,8.551,8.551,0,0,0-2.307,5.7,8.112,8.112,0,0,0,16.225,0c0-4.046-2.237-6.113-4.21-7.936a15.371,15.371,0,0,1-2.018-2.1,4.06,4.06,0,0,1-.871-2.473,1,1,0,0,0-1-1.009H250.7a1.019,1.019,0,0,0-.849.457c-1.519,2.22-2.1,4.019-1.779,5.5a6.3,6.3,0,0,0,2.1,3.078c.966.938,1.877,1.824,1.877,3.128a2.707,2.707,0,0,1-2.7,2.7h-.036a2.663,2.663,0,0,1-1.891-.822,2.751,2.751,0,0,1-.777-1.922v-3.612a1,1,0,0,0-.315-.738A1.046,1.046,0,0,0,245.629,460.656Z"
        fill="#3d3d3d"
      />
      <g
        id={configValues.projectName}
        transform="translate(272.463 452.38)"
        style={{
          mixBlendMode: "normal",
          isolation: "isolate",
        }}
      >
        <g
          id="Group_278"
          data-name="Group 278"
          transform="translate(0 0)"
          style={{
            mixBlendMode: "normal",
            isolation: "isolate",
          }}
        >
          <path
            id="Path_797"
            data-name="Path 797"
            d="M364.417,465.039a1.584,1.584,0,0,1-.49-1.212,1.54,1.54,0,0,1,.49-1.2,2.171,2.171,0,0,1,2.639,0,1.521,1.521,0,0,1,.5,1.2,1.586,1.586,0,0,1-.49,1.212,2.137,2.137,0,0,1-2.654,0Zm.274,17.453a1.27,1.27,0,0,1-.418-1.038V469.741a1.315,1.315,0,0,1,.418-1.053,1.555,1.555,0,0,1,1.053-.361,1.5,1.5,0,0,1,1.039.361,1.339,1.339,0,0,1,.4,1.053v11.712a1.292,1.292,0,0,1-.4,1.038,1.541,1.541,0,0,1-1.039.346A1.6,1.6,0,0,1,364.691,482.491Z"
            transform="translate(-363.927 -462.183)"
            fill="#3d3d3d"
          />
          <path
            id="Path_798"
            data-name="Path 798"
            d="M401.717,488.764v7.5a1.326,1.326,0,0,1-.39,1.024,1.533,1.533,0,0,1-1.082.361,1.455,1.455,0,0,1-1.053-.375,1.336,1.336,0,0,1-.39-1.01v-7.356a4.023,4.023,0,0,0-.707-2.625,2.731,2.731,0,0,0-2.207-.837,3.766,3.766,0,0,0-2.827,1.1A4.037,4.037,0,0,0,392,489.485v6.779a1.336,1.336,0,0,1-.39,1.01,1.456,1.456,0,0,1-1.053.375,1.508,1.508,0,0,1-1.068-.375,1.311,1.311,0,0,1-.4-1.01V484.523a1.3,1.3,0,0,1,.419-1,1.484,1.484,0,0,1,1.053-.389,1.368,1.368,0,0,1,1.009.375,1.328,1.328,0,0,1,.375.981v1.182a4.687,4.687,0,0,1,1.933-1.932,5.839,5.839,0,0,1,2.8-.664Q401.717,483.081,401.717,488.764Z"
            transform="translate(-381.755 -476.994)"
            fill="#3d3d3d"
          />
          <path
            id="Path_799"
            data-name="Path 799"
            d="M445.506,465.556a3.435,3.435,0,0,0-.692,2.322v.808h2.856a1.339,1.339,0,0,1,.937.3,1.185,1.185,0,0,1,0,1.644,1.341,1.341,0,0,1-.937.3h-2.856v10.558a1.314,1.314,0,0,1-.418,1.053,1.558,1.558,0,0,1-1.053.361,1.5,1.5,0,0,1-1.039-.361,1.337,1.337,0,0,1-.4-1.053V470.935h-1.731a1.342,1.342,0,0,1-.938-.3,1.185,1.185,0,0,1,0-1.644,1.34,1.34,0,0,1,.938-.3H441.9v-.375a6.025,6.025,0,0,1,1.355-4.168,5.42,5.42,0,0,1,3.837-1.688l.75-.058a2.355,2.355,0,0,1,1.341.2.912.912,0,0,1,.419.837q0,1.01-1.212,1.154l-.75.058A3.024,3.024,0,0,0,445.506,465.556Zm5.784,17.005a1.27,1.27,0,0,1-.418-1.038V463.695a1.315,1.315,0,0,1,.418-1.053,1.556,1.556,0,0,1,1.053-.361,1.5,1.5,0,0,1,1.039.361,1.34,1.34,0,0,1,.4,1.053v17.828a1.293,1.293,0,0,1-.4,1.038,1.542,1.542,0,0,1-1.039.346A1.6,1.6,0,0,1,451.29,482.561Z"
            transform="translate(-417.063 -462.252)"
            fill="#3d3d3d"
          />
          <path
            id="Path_800"
            data-name="Path 800"
            d="M512.86,484.495a5.965,5.965,0,0,1,1.37,4.3v7.471a1.354,1.354,0,0,1-.375,1.024,1.437,1.437,0,0,1-1.038.361,1.292,1.292,0,0,1-1.385-1.385V495.2a4.058,4.058,0,0,1-1.63,1.861,4.813,4.813,0,0,1-2.524.649,5.717,5.717,0,0,1-2.539-.562,4.357,4.357,0,0,1-1.8-1.573,4.071,4.071,0,0,1-.649-2.25,3.463,3.463,0,0,1,.794-2.409,4.734,4.734,0,0,1,2.625-1.255,26.426,26.426,0,0,1,5.034-.375h.664v-.836a3.391,3.391,0,0,0-.664-2.337,2.779,2.779,0,0,0-2.135-.721,6.786,6.786,0,0,0-1.9.26,14.3,14.3,0,0,0-1.962.75,2.831,2.831,0,0,1-1.1.433.889.889,0,0,1-.707-.318,1.19,1.19,0,0,1-.274-.808,1.252,1.252,0,0,1,.245-.765,2.586,2.586,0,0,1,.793-.649,9.869,9.869,0,0,1,2.365-.88,11.036,11.036,0,0,1,2.625-.332A5.565,5.565,0,0,1,512.86,484.495Zm-2.467,9.967a3.916,3.916,0,0,0,1.01-2.784v-.75h-.519a22.517,22.517,0,0,0-3.462.2,3.4,3.4,0,0,0-1.731.678,1.766,1.766,0,0,0-.519,1.37,2.167,2.167,0,0,0,.75,1.7,2.723,2.723,0,0,0,1.875.664A3.392,3.392,0,0,0,510.393,494.461Z"
            transform="translate(-461.988 -476.994)"
            fill="#3d3d3d"
          />
          <path
            id="Path_801"
            data-name="Path 801"
            d="M577.844,488.764v7.5a1.3,1.3,0,0,1-.4,1.024,1.546,1.546,0,0,1-1.067.361,1.5,1.5,0,0,1-1.039-.361,1.3,1.3,0,0,1-.4-1.024v-7.471a4.1,4.1,0,0,0-.635-2.553,2.4,2.4,0,0,0-1.99-.794,3.126,3.126,0,0,0-2.51,1.082,4.406,4.406,0,0,0-.923,2.957v6.779a1.3,1.3,0,0,1-.4,1.024,1.545,1.545,0,0,1-1.067.361,1.5,1.5,0,0,1-1.038-.361,1.3,1.3,0,0,1-.4-1.024v-7.471a4.1,4.1,0,0,0-.635-2.553,2.4,2.4,0,0,0-1.99-.794,3.169,3.169,0,0,0-2.524,1.082,4.363,4.363,0,0,0-.938,2.957v6.779a1.3,1.3,0,0,1-.4,1.024,1.5,1.5,0,0,1-1.039.361,1.556,1.556,0,0,1-1.053-.361,1.278,1.278,0,0,1-.418-1.024V484.523a1.269,1.269,0,0,1,.432-1.01,1.536,1.536,0,0,1,1.039-.375,1.408,1.408,0,0,1,1,.361,1.29,1.29,0,0,1,.389,1v1.067a4.409,4.409,0,0,1,1.76-1.846,5.2,5.2,0,0,1,2.6-.635,4.926,4.926,0,0,1,2.668.664,3.964,3.964,0,0,1,1.572,2.048,4.815,4.815,0,0,1,1.875-1.976,5.434,5.434,0,0,1,2.827-.735Q577.844,483.081,577.844,488.764Z"
            transform="translate(-500.736 -476.994)"
            fill="#3d3d3d"
          />
          <path
            id="Path_802"
            data-name="Path 802"
            d="M663.218,488.764v7.5a1.3,1.3,0,0,1-.4,1.024,1.545,1.545,0,0,1-1.067.361,1.5,1.5,0,0,1-1.038-.361,1.3,1.3,0,0,1-.4-1.024v-7.471a4.1,4.1,0,0,0-.634-2.553,2.4,2.4,0,0,0-1.99-.794,3.126,3.126,0,0,0-2.51,1.082,4.406,4.406,0,0,0-.923,2.957v6.779a1.3,1.3,0,0,1-.4,1.024,1.544,1.544,0,0,1-1.067.361,1.5,1.5,0,0,1-1.038-.361,1.3,1.3,0,0,1-.4-1.024v-7.471a4.1,4.1,0,0,0-.635-2.553,2.4,2.4,0,0,0-1.99-.794,3.169,3.169,0,0,0-2.524,1.082,4.363,4.363,0,0,0-.937,2.957v6.779a1.3,1.3,0,0,1-.4,1.024,1.5,1.5,0,0,1-1.038.361,1.555,1.555,0,0,1-1.053-.361,1.277,1.277,0,0,1-.419-1.024V484.523a1.269,1.269,0,0,1,.433-1.01,1.535,1.535,0,0,1,1.039-.375,1.408,1.408,0,0,1,1,.361,1.289,1.289,0,0,1,.389,1v1.067a4.411,4.411,0,0,1,1.76-1.846,5.2,5.2,0,0,1,2.6-.635,4.924,4.924,0,0,1,2.668.664,3.967,3.967,0,0,1,1.572,2.048,4.818,4.818,0,0,1,1.875-1.976,5.435,5.435,0,0,1,2.827-.735Q663.218,483.081,663.218,488.764Z"
            transform="translate(-561.244 -476.994)"
            fill="#3d3d3d"
          />
          <path
            id="Path_803"
            data-name="Path 803"
            d="M728.338,500.52a1.857,1.857,0,0,1,1.327.462,1.586,1.586,0,0,1,.49,1.212,1.521,1.521,0,0,1-.5,1.2,2.171,2.171,0,0,1-2.64,0,1.54,1.54,0,0,1-.49-1.2,1.585,1.585,0,0,1,.49-1.212A1.855,1.855,0,0,1,728.338,500.52Zm0-17.337a1.541,1.541,0,0,1,1.038.346,1.293,1.293,0,0,1,.4,1.039V496.28a1.34,1.34,0,0,1-.4,1.053,1.5,1.5,0,0,1-1.038.361,1.556,1.556,0,0,1-1.053-.361,1.315,1.315,0,0,1-.419-1.053V484.568a1.27,1.27,0,0,1,.419-1.039A1.6,1.6,0,0,1,728.338,483.183Z"
            transform="translate(-620.912 -477.067)"
            fill="#3d3d3d"
          />
        </g>
      </g>
    </g>
  </svg>
);
