import styled from "styled-components";
import { ArrowLongRightSvg } from "../../assets/svg/ArrowLongRight";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../styles/global";
import { useProfile } from "../../state/profile";
import { useEffect } from "react";
import { configValues } from "../../config-values";

const Container = styled.div`
  max-height: 100vh;
  display: flex;
  padding-top: 4rem;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  width: 80%;
`;

const Buttons = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  gap: 1rem;
  display: flex;
  padding: 2rem;
  position: absolute;
  flex-direction: column;
`;

const ButtonLink = styled(Link)`
  width: auto;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  align-items: center;
  text-decoration: none;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
`;

const Vertical = styled.div`
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 0;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 600;
`;

export const Main = () => {
  const { profile } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      if (profile._id && profile.active) {
        navigate("/explore");
      } else if (profile._id) {
        alert("Subscription expired");
      }
    }
  }, [profile, navigate]);
  const logo = require(`../../assets/images/${configValues.project.logo}`);

  const {
    LogoSvg,
  } = require(`../../assets/svg/Logos/${configValues.project.logoSvg}`);

  return (
    <Container>
      <LogoSvg />
      <div style={{ height: "3rem" }} />
      <Image alt="Hero" src={logo} />
      <Buttons>
        <ButtonLink
          to={
            configValues.project.inReview
              ? `${configValues.websiteUrl}register-app`
              : `${configValues.websiteUrl}quiz`
          }
        >
          <Vertical>
            <Label>New to {configValues.project.marketedTitle}?</Label>
            <span>Register</span>
          </Vertical>
          <ArrowLongRightSvg />
        </ButtonLink>
        <ButtonLink to="/login">
          <Vertical>
            <Label>Already have an account?</Label>
            <span>Login</span>
          </Vertical>
          <ArrowLongRightSvg />
        </ButtonLink>
      </Buttons>
    </Container>
  );
};
