import styled from "styled-components";
import { ProfileHeader } from "../../components/nav/ProfileHeader";
import { TabBar } from "../../components/nav/TabBar";
import { SettingsItem } from "../../components/items/SettingsItem";
import { ArrowRight } from "../../assets/svg/ArrowRight";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../state/profile";
import { formatHeight, formatWeight, getFullName } from "../../utils/profile";
import { capitalize } from "lodash";
import { useMealPlan } from "../../state/mealPlan";
import { useShoppingList } from "../../state/shoppingList";
import { Api } from "../../apis/api";
import { configValues } from "../../config-values";

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding-bottom: 90px;
  flex-direction: column;
  background-color: rgba(245, 245, 245, 1);
`;

const Section = styled.div<{ $bg?: string }>`
  display: flex;
  margin: 0 1rem;
  margin-top: 1rem;
  border-radius: 12px;
  flex-direction: column;
  background-color: ${(p) => p.$bg || "white"};
`;

const Separator = styled.div`
  height: 1px;
  width: auto;
  margin: 0 1rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const Profile = () => {
  const navigate = useNavigate();
  const { resetMealPlan } = useMealPlan();
  const { resetShoppingList } = useShoppingList();
  const { profile, resetProfile } = useProfile();

  const logout = () => {
    resetProfile();
    resetMealPlan();
    resetShoppingList();
    navigate("/", {
      replace: true,
    });
  };

  const deleteUser = () => {
    resetProfile();
    resetMealPlan();
    resetShoppingList();
    navigate("/", {
      replace: true,
    });
    Api.deleteUser(profile._id).catch((e) => {
      Api.reportError(e, {
        fn: "deleteUser",
        email: profile.email,
      });
    });
  };

  const reviewerEmail = localStorage.getItem("reviewer-email-stored");

  return (
    <Container>
      <ProfileHeader {...profile} />
      <Content>
        <Section>
          <SettingsItem
            title="Name"
            link="/profile/name"
            icon={<ArrowRight />}
            selection={getFullName(profile)}
          />
          <Separator />
          <SettingsItem
            title="Email"
            selection={reviewerEmail ?? profile.email}
          />
          <Separator />
          <SettingsItem
            title="Units"
            link="/profile/units"
            icon={<ArrowRight />}
            selection={capitalize(profile.units)}
          />
          <Separator />
          <SettingsItem
            title="Height"
            link="/profile/height"
            icon={<ArrowRight />}
            selection={formatHeight(profile)}
          />
          <Separator />
          <SettingsItem
            title="Starting weight"
            link="/profile/weight"
            icon={<ArrowRight />}
            selection={formatWeight(profile)}
          />
          <Separator />
          <SettingsItem
            title="Target weight"
            link="/profile/goal"
            selection={formatWeight(profile, "goal")}
            icon={<ArrowRight />}
          />
        </Section>
        <Section>
          <SettingsItem
            title="Terms & Conditions"
            link={`${configValues.websiteUrl}general-conditions`}
            icon={<ArrowRight />}
          />
          <Separator />
          <SettingsItem
            title="Privacy Policy"
            icon={<ArrowRight />}
            link={`${configValues.websiteUrl}privacy-policy`}
          />
          <Separator />
          <SettingsItem
            title="Contact Us"
            link={`${configValues.websiteUrl}contacts`}
            icon={<ArrowRight />}
          />
          <Separator />
          <SettingsItem title="Version" selection="1.0.1" />
        </Section>
        <Section $bg="#f6dfdf">
          <SettingsItem title="Logout" onClick={logout} icon={<ArrowRight />} />
        </Section>
        <Section $bg="#f6dfdf">
          <SettingsItem
            title="Delete Account"
            onClick={deleteUser}
            icon={<ArrowRight />}
          />
        </Section>
      </Content>
      <TabBar />
    </Container>
  );
};
