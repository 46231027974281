import { BackHeader } from "../../components/nav/BackHeader";
import styled from "styled-components";
import { useState } from "react";
import { Colors } from "../../styles/global";
import { InfoItem } from "../../components/items/InfoItem";
import { configValues } from "../../config-values";

const Container = styled.div`
  height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  gap: 1rem;
  display: flex;
  padding: 0 2rem;
  padding-top: 6rem;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 11px 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  background-color: ${configValues.project.inputBgColor};
  border: 0.125rem solid ${configValues.project.inputBorderColor};
`;

const TextArea = styled.textarea`
  padding: 11px 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  background-color: ${configValues.project.inputBgColor};
  border: 0.125rem solid ${configValues.project.inputBorderColor};
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
`;

export const DeleteView = () => {
  const [sent, setSent] = useState(false);

  const onSubmit = async () => {
    setSent(true);
  };

  const message =
    "You account and associated data will be deleted in 24 hours.";

  return (
    <Container>
      <BackHeader title="Account removal" />
      <Content>
        {sent && <InfoItem mb="1rem" info={message} />}
        <Input type="email" placeholder="Enter email" />
        <TextArea placeholder="Please provide more info (e.g., type of data you would like to be removed)" />
        <Button onClick={onSubmit}>Delete my account</Button>
      </Content>
    </Container>
  );
};
