import { Route, Routes } from "react-router-dom";

import { BrowserRouter } from "react-router-dom";
import { ExploreView } from "./explore/ExploreView";
import { MealPlanView } from "./meals/MealPlanView";
import { Profile } from "./profile";
import { ShoppingListInitial } from "./shopping/ShoppingListInitial";
import { MealView } from "./meals/MealView";
import { CreateShoppingList } from "./shopping/CreateShoppingList";
import { ShoppingList } from "./shopping/ShoppingList";
import { EditView } from "./profile/EditView";
import { Main } from "./auth";
import { LoginView } from "./auth/LoginView";
import { ForgotView } from "./auth/ForgotView";
import { MealPlanInitial } from "./meals/MealPlanInitial";
import { ChangeMealView } from "./meals/ChangeMealView";
import { DeleteView } from "./delete/DeleteView";
import { ReviewerProvider } from "../contexts/ReviewerProvider";

export const Router = () => (
  <BrowserRouter>
    <ReviewerProvider>
      <Routes>
        <Route path={"/"} element={<Main />} />
        <Route path={"/login"} element={<LoginView />} />
        <Route path={"/forgot"} element={<ForgotView />} />
        <Route path={"/delete"} element={<DeleteView />} />
        <Route path={"/explore"} element={<ExploreView />} />
        <Route path={"/meals/initial"} element={<MealPlanInitial />} />
        <Route path={"/meals/plan"} element={<MealPlanView />} />
        <Route path={"/meals/change/:mealId"} element={<ChangeMealView />} />
        <Route path={"/meals/plan/:mealId"} element={<MealView />} />
        <Route path={"/shopping/initial"} element={<ShoppingListInitial />} />
        <Route path={"/shopping/create"} element={<CreateShoppingList />} />
        <Route path={"/shopping/list"} element={<ShoppingList />} />
        <Route path={"/profile"} element={<Profile />} />
        <Route path={"/profile/:type"} element={<EditView />} />
      </Routes>
    </ReviewerProvider>
  </BrowserRouter>
);
