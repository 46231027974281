type TScienceSource = {
  title: string;
  url: string;
};

type TProjectConfigValues = {
  marketedTitle: string;
  logo: string;
  logoSvg: string;
  primaryColor: string;
  fontPrimaryColor: string;
  secondaryBg: string;
  fontInfo: string;
  inputBgColor: string;
  inputBorderColor: string;
  inReview: boolean;
  sources: TScienceSource[];
  appTesterEmail: string;
  appTesterPassword: string;
};

type TProjectConfig = {
  inflammi: TProjectConfigValues;
  mediplan: TProjectConfigValues;
};

const projectConfig: TProjectConfig = {
  inflammi: {
    marketedTitle: "Inflammi",
    logo: "logo.png",
    logoSvg: "InflammiLogoSvg.tsx",
    primaryColor: "#f6c26e",
    fontPrimaryColor: "#3d3d3d",
    secondaryBg: "#ffe8c2",
    fontInfo: "#848484",
    inputBgColor: "#e9edf3",
    inputBorderColor: "#e9edf3",
    inReview: true,
    sources: [
      {
        title: "Foods that fight inflammation",
        url: "https://www.health.harvard.edu/staying-healthy/foods-that-fight-inflammation",
      },
      {
        title: "Anti Inflammatory Diet",
        url: "https://www.hopkinsmedicine.org/health/wellness-and-prevention/anti-inflammatory-diet",
      },
      {
        title: "Anti-Inflammatory Diet Do's and Don'ts",
        url: "https://www.arthritis.org/health-wellness/healthy-living/nutrition/anti-inflammatory/anti-inflammatory-diet",
      },
      {
        title: "Quick-start guide to an anti‑inflammation diet",
        url: "https://www.health.harvard.edu/staying-healthy/quick-start-guide-to-an-antiinflammation-diet",
      },
      {
        title: "Why and How To Start an Anti-Inflammatory Diet",
        url: "https://health.clevelandclinic.org/anti-inflammatory-diet",
      },
    ],
    appTesterEmail: 'app.tester@inflammiplan.com',
    appTesterPassword: '123456'
  },
  mediplan: {
    marketedTitle: "MediPlan",
    logo: "logo.png",
    logoSvg: "MediLogoSvg.tsx",
    primaryColor: "rgb(63, 161, 52)",
    fontPrimaryColor: "#ffffff",
    secondaryBg: "#dafdd7",
    fontInfo: "rgb(29, 35, 38)",
    inputBgColor: "#ffffff",
    inputBorderColor: "rgb(185, 207, 204)",
    inReview: true,
    sources: [
      {
        title: "Mediterranean Diet",
        url: "https://my.clevelandclinic.org/health/articles/16037-mediterranean-diet",
      },
      {
        title: "Diet Review: Mediterranean Diet",
        url: "https://nutritionsource.hsph.harvard.edu/healthy-weight/diet-reviews/mediterranean-diet/",
      },
      {
        title: "What is the Mediterranean Diet?",
        url: "https://www.heart.org/en/healthy-living/healthy-eating/eat-smart/nutrition-basics/mediterranean-diet",
      },
      {
        title: "A practical guide to the Mediterranean diet",
        url: "https://www.health.harvard.edu/blog/a-practical-guide-to-the-mediterranean-diet-2019032116194",
      },
    ],
    appTesterEmail: 'app.tester@getmediplan.com',
    appTesterPassword: '123456'
  },
};

type TConfigValues = {
  apiUrl: string;
  projectName: string;
  websiteUrl: string;
  project: TProjectConfigValues;
};

export const configValues: TConfigValues = {
  apiUrl: process.env.REACT_APP_API_URL ?? "",
  projectName: process.env.REACT_APP_PROJECT_NAME ?? "",
  websiteUrl: process.env.REACT_APP_WEBSITE_URL ?? "",
  project: projectConfig[process.env.REACT_APP_PROJECT_NAME],
};
