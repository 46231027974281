import { BackHeader } from "../../components/nav/BackHeader";
import styled from "styled-components";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../styles/global";
import { InfoItem } from "../../components/items/InfoItem";
import { useProfile } from "../../state/profile";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { v } from "../../utils/validations";
import { IAppError, getErrors } from "../../utils/formErrors";
import { configValues } from "../../config-values";

const Container = styled.div`
  height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  gap: 1rem;
  display: flex;
  padding: 0 2rem;
  padding-top: 6rem;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 11px 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  background-color: ${configValues.project.inputBgColor};
  border: 0.125rem solid ${configValues.project.inputBorderColor};
`;

const InputError = styled.span`
  font-size: 12px;
  padding-left: 0.25rem;
  color: ${Colors.fontError};
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
`;

const ButtonLink = styled(Link)`
  color: ${Colors.fontInfo};
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
`;

export const LoginView = () => {
  const { login } = useProfile();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAppError>({});

  const onSubmit = async () => {
    try {
      setErrors({});
      setLoading(true);
      const data = { email, password };
      await v.login.validateSync(data, { abortEarly: false });
      const p = await login(data);
      if (p.email && p._id) {
        navigate("/explore");
      }
    } catch (e) {
      setErrors(getErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <BackHeader title="Login" />
      <Content>
        <InfoItem
          mb="0.5rem"
          info={`Welcome back! Please enter email & password associated with your ${configValues.project.marketedTitle} account.`}
        />
        {errors.error && <InfoItem error={true} info={errors.error} />}
        <InputContainer>
          <Input
            value={email}
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors?.email && <InputError>{errors.email}</InputError>}
        </InputContainer>
        <InputContainer>
          <Input
            value={password}
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors?.password && <InputError>{errors.password}</InputError>}
        </InputContainer>
        <ButtonLink to="/forgot">Forgot password?</ButtonLink>
        <Button onClick={onSubmit}>
          {loading ? <LoaderSvg /> : "Continue"}
        </Button>
      </Content>
    </Container>
  );
};
